import { BaseWidget } from './base-widget';

export class Openinghours extends BaseWidget {
    static NAME = 'openinghours';

   constructor(element) {
        super(element);
        this.today = new Date();
        this.date = this.today.getDay();
    }

    init() {
       switch(this.date) {
            case 0:
                $('.o-openinghours__list li:nth-of-type(8)').addClass('is-active');
            break;
            case 1:
                $('.o-openinghours__list li:nth-of-type(2)').addClass('is-active');
            break;
            case 2:
                $('.o-openinghours__list li:nth-of-type(3)').addClass('is-active');
            break;
            case 3:
                $('.o-openinghours__list li:nth-of-type(4)').addClass('is-active');
            break;
            case 4:
                $('.o-openinghours__list li:nth-of-type(5)').addClass('is-active');
            break;
            case 5:
                $('.o-openinghours__list li:nth-of-type(6)').addClass('is-active');
            break;
            case 6:
                $('.o-openinghours__list li:nth-of-type(7)').addClass('is-active');
            break;

          default:
            // code block
        }

    }
}
