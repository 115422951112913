import { throttle } from 'lodash';
import { BaseWidget } from './base-widget';

export class Header extends BaseWidget {
    static NAME = 'header';

    constructor(element) {
        super(element);
        this.navItems = $('[data-nav-item]', this.element);
    }

    init() {
         window.addEventListener('scroll', throttle(this.handleScroll.bind(this), 20));
        //  this.hideHeader()
    }

    handleScroll() {
        const scrollPosY = window.pageYOffset | document.body.scrollTop;

        if (scrollPosY > 200 ) {
            this.element.addClass('is-scrolled');
            $('body').addClass('is-scrolled');
        } else {
            this.element.removeClass('is-scrolled');
            $('body').removeClass('is-scrolled');
        }
    }


    hideHeader() {
        let scrollTimeOut = true,
        lastYPos = 0,
        yPos = 0,
        yPosDelta = 5,
        nav = $('.header'),
        navHeight = nav.outerHeight(),
        setNavClass = function() {
            scrollTimeOut = false;
            yPos = $(window).scrollTop();

            if(Math.abs(lastYPos - yPos) >= yPosDelta) {
                if (yPos > lastYPos && yPos > navHeight){
                    nav.addClass('is-hidden');
                } else {
                    nav.removeClass('is-hidden');
                }
                lastYPos = yPos;
            }
        };

        $(window).scroll(function(e){
            scrollTimeOut = true;
        });

        setInterval(function() {
            if (scrollTimeOut) {
                setNavClass();
            }

        }, 250);
    }
}
